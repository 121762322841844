body{
    font-family: 'PT Sans', sans-serif;
}

header{
    border-bottom: 7px solid #2189C5;
    padding: 0 150px;

    @media (max-width: 1200px) {
        padding: 0 50px;
    }

    @media (max-width: 991px){
        border-bottom: 3px solid #2189C5;
    }

    @media (max-width: 767px) {
        padding: 0;
    }

    .navbar{
      position: static;
      @media (min-width: 768px) and (max-width: 991px){
        align-items: flex-end;
      }
    }

    .logo{
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        margin-bottom: -65px;
        width: 216px;
        border-radius: 50%;
        text-align: center;

        @media (max-width: 991px){
            width: 105px;
            margin-right: 0;
            margin-bottom: -40px;
        }

        img{
            position: relative;
            z-index: 4;
            width: 157px;

            @media (max-width: 991px){
                width: 73px;
            }
        }
        
        &:before{
            content: "";
            background: #fff;
            position: absolute;
            z-index: 3;
            width: 100%;
            height: 67%;
            top: -10px;

            @media (max-width: 991px){
              width: 105px;
              height: 60px;
              top: -20px;
            }
        }

        &:after{
            content: '';
            border: 7px solid #2189c5;
            width: 216px;
            height: 216px;
            border-radius: 0 0 50% 50%;
            display: inline-block;
            position: absolute;
            z-index: 2;
            top: -50px;
            background: #fff;

            @media (max-width: 991px){
                border: 3px solid #2189c5;
                width: 100px;
                height: 100px;
                top: -20px;
            }
        }

    }
}

.navbar-toggler{
    padding: 0;
    border: none;
    outline: none!important;
    position: relative;
    z-index: 6;
}

.header-rest{
    display: flex;
    justify-content: flex-end;
    flex: 1;
    
    @media (min-width: 768px){
      align-items: baseline;
    }

    .navbar-nav .nav-link{
        color: #515255;
        border-bottom: 2px solid transparent;

        &:hover{
          border-bottom: 2px solid #1A8F3E;
        }

        &_active{
          border-bottom: 2px solid #1A8F3E;

          @media (max-width: 768px){
            color: #1A8F3E;
            border-bottom: 2px solid transparent;
          }
        }
    }

    *{
        color: #515255;
        line-height: 1;
    }

    .nav-item{
        margin-right: 60px;

        @media (max-width: 1200px) {
            margin-right: 40px;
        }
    }

    @media (max-width: 991px){
        justify-content: space-between;
    }
}

@media (min-width: 768px){
    .navbar-expand-md .navbar-collapse {
        justify-content: flex-end;
    }
}

.header-tel{

    @media (max-width: 991px){
      line-height: 10px;
    }
    
    a{
      font-size: 24px;

      @media (max-width: 991px){
          font-size: 16px;
      }
    }

    span{
        font-size: 12px;

        @media (max-width: 991px){
            font-size: 8px;
        }
    }
}

.navbar-collapse{
  flex: 0;
  flex-grow: 0;
  font-size: 24px;
  order: -1;

  @media (max-width: 991px){
      font-size: 16px;
  }

  @media (max-width: 991px) and (min-width: 768px){
    margin: auto;
  }

  @media (max-width: 767px){
    opacity: 0;
    display: block !important;
    z-index: -1;
    transition: opacity .3s linear;

     ul{
      position: absolute;
      top: 0;
      right: 0;
      padding: 30px 0;
      background: #fff;
      max-width: 275px;
      width: 100%;
      box-shadow: 1px 1px 19px 5px #000;
      z-index: 0;
      display: block;
      padding-left: 35px;
  
      li:not(:last-child){
        border-bottom: 1px solid #C4C4C4;
      }
    }
  }
}

.navbar-collapse.collapse.show {
  opacity: 1;
  transition: opacity .3s linear;
  z-index: 4;

  &:before{
    content: '';
    position: absolute;
    background: rgba(0,0,0,0.4);
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
  }
}

.collapsing {  height: auto!important; } 

.navbar-nav{
  @media (min-width: 762px){
    li:not(:first-child):not(:last-child){
      display: none;
    }
  }
}

.animated-icon2 {
  width: 30px;
  height: 20px;
  position: relative;
  margin: 0px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 1s ease-in-out;
  -moz-transition: 1s ease-in-out;
  -o-transition: 1s ease-in-out;
  transition: 1s ease-in-out;
  cursor: pointer;

  span {
      display: block;
      position: absolute;
      height: 3px;
      width: 100%;
      border-radius: 9px;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: .7s ease-in-out;
      -moz-transition: .7s ease-in-out;
      -o-transition: .7s ease-in-out;
      transition: .7s ease-in-out;
      background: #515255;
    }
}

.animated-icon2 span:nth-child(1) {
  top: 0px;
}

.animated-icon2 span:nth-child(2), .animated-icon2 span:nth-child(3) {
  top: 10px;
}

.animated-icon2 span:nth-child(4) {
  top: 20px;
}

.animated-icon2.open span:nth-child(1) {
  top: 11px;
  width: 0%;
  left: 50%;
}

.animated-icon2.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.animated-icon2.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.animated-icon2.open span:nth-child(4) {
  top: 11px;
  width: 0%;
  left: 50%;
}

.owl-stage {
  touch-action: manipulation;
}
  
.main-slider{
  overflow: hidden;

  .owl-stage{
    display: flex;
  }
}
  
.main-slider__slide {
  background-size: cover;
  min-height: 578px;
  display: flex;
  align-items: center;
  height: 100%;

  @media (max-width: 1440px) {
    min-height: 500px;
  }
  
  @media (max-width: 767px){
    min-height: 400px;
  }

  @media (max-width: 640px) {
    min-height: 360px;
  }

  @media (max-width: 460px) {
    min-height: 210px;
  }

  &_1 {
    background: url('../img/main-slide-1.jpg') no-repeat;
    background-position: 100% 85%;
    background-size: cover;
  }

  &_2 {
    background: url('../img/proizvodstvo1.jpg') no-repeat;
    background-position: 100% 60%;
    background-size: cover;

    @media (max-width: 460px) {
      background-position: 66%;
      
      .main-slider__slide-text {
          padding-right: 40px;
      }

      &:before{
        content: '';
        background: rgba(255, 255, 255, 0.2);
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 1;
      }
    }
  }

  &_3 {
    background: url('../img/stroitelstvo1.jpg') no-repeat;
    background-position: 70%;
    background-size: cover;

    @media (max-width: 460px){
      background-position: 68%;

      &:before{
        content: '';
          background: rgba(255, 255, 255, 0.2);
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          z-index: 1;
      }

      .main-slider__slide-text {
          padding-right: 40px;
      }
    }
  }

  &_4 {
    background: url('../img/garantinoe1.jpg') no-repeat;
    background-position: 50% 100%;
    background-size: cover;

    @media (max-width: 460px) {
      background-position: 68%;

      &:before{
        content: '';
        background: rgba(255, 255, 255, 0.2);
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 1;
      }

      .main-slider__slide-text {
        padding-right: 40px;
      }
    }
  }

  &_object-1 {
    background: url('../img/object-slide-1.png') no-repeat;
    background-position: top;
    background-size: cover;

    @media (max-width: 1880px) {
      //background: url('../img/slide-1.png') no-repeat left 60%;
    }
  }

  &-title {
    font-size: 64px;
    line-height: 1.2;
    margin-bottom: 60px;


    @media (min-width: 768px){
      line-height: 65px;
    }

    @media (max-width: 767px){
      font-size: 32px;
      margin-bottom: 30px;
    }



    @media (max-width: 460px) {
      font-size: 24px;
      margin-bottom: 10px;
    }


    + div {
      margin: 16px 0;

      @media (max-width: 460px) {
        margin: 10px 0 0;
      }
    }
  }

  &-text {
    padding: 70px 30px 0px;
    color:  #515255;
    max-width: 666px;
    width: 100%;
    font-size: 18px;
    //margin-left: 150px;
    position: relative;
    z-index: 2;

    @media (max-width: 1200px) {
      //margin-left: 50px;
    }

    @media (max-width: 767px) {
      font-size: 16px;
      // margin-left: 0;
    }

    @media (max-width: 460px) {
      font-size: 11px;
      padding: 40px 15px 15px;
    }
  }
}
  
.main-slider-section {
  position: relative;

  @media (max-width: 575px) {
    + .page-content{ 
      padding-top: 20px
    }
  }

  @media (max-width: 640px) {
    .container{ 
      padding: 0
    }
  }

  .owl-nav {
    @extend .container;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: left;
    display: flex;
    z-index: 4;
    bottom: 70px;

    img {
      width: auto !important;
    }

    .owl-prev {
      margin-right: 40px;
    }

    .disabled {
      opacity: 0.4;
    }
  }
}
  
.owl-carousel .owl-item .owl-robot {
  width: auto;
  position: absolute;

  @media (max-width: 640px) {
    width: 60px;
  }

  &_bottom {
    bottom: 0;
  }

  &_top {
    top: 0;
    right: 0;
  }
}

.owl-nav.disabled{
    display: none;
}

.object{
  text-align: center;
  min-height: 480px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 32px;

  @media (max-width: 575px){
    min-height: 270px;
    padding: 15px;
  }

      a {
        color: #fff;
        &:hover{
            opacity: 0.8;
            text-decoration: none;
        }
      }

      &-title{
          font-size: 36px;
          display: inline-block;

          @media (max-width: 991px){
            font-size: 24px;
            line-height: 31px;
          }

          &-wrapper{
              flex: 1;
              align-items: center;
              display: flex;
              justify-content: center;
              max-width: 75%;
          }
      }

      &-detailed{
          border: 1px solid #fff;
          border-radius: 25px;
          padding: 9px 26px;

          @media (max-width: 575px){
            display: none;
          }
      
      }

    &_1{
      background: url('../img/object-1.png') no-repeat;
      background-size: cover;
    }

    &_2{
      background: url('../img/object-2.png') no-repeat;
      background-size: cover;
    }

    &_3{
      background: url('../img/object-3.png') no-repeat;
      background-size: cover;
    }
}

.objects-submenu{
  list-style: none;
  padding: 0 15px;

  @media (max-width: 1200px){
    padding: 0 50px;
  }

  @media (max-width: 767px){
    display: none;
  }

  &-section{
    background: linear-gradient(270deg, #77C6C0 0%, rgba(255, 255, 255, 0) 100%), #01ADE9;
  }

  li{
    background: #2D605D;
    text-align: center;
    padding: 10px;
    border-left: 1px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;

    &:first-child{
      background: #0092C6;
    }

    &:last-child{
      background: #003F74;
    }
  }

  a{
    font-size: 18px;
    line-height: 23px;
    color: #fff;
    max-width: 270px;
    text-decoration: none;

    &:hover{
      text-decoration: underline;
    }

    &.current{
      color: #C4C4C4;
      text-decoration: none;
    }

    
    @media (max-width: 991px){
      font-size: 14px;
      line-height: 1.2;
    }
  }
}

.page-content{
  padding: 50px 0;

  .h1-content{
    margin-bottom: 20px;
    color: #173646;
  }

  p{
    @media (min-width: 767px){
      font-size: 24px;
      line-height: 31px;
      margin-bottom: 25px;
      color: #173646;
    }
  }

  hr{
    margin: 50px 0;
    background: #515255;

    @media (max-width: 575px){
      margin: 30px 0;
    }
  }

  ul{
    list-style-position: inside;
    padding-left: 0;
  }

  h1{
    font-weight: 700;
    color: #1A3949;

    @media (min-width: 767px){
      font-size: 36px;
    }
  }

  h5{
    font-size: 24px;
    font-weight: 700;
    color: #515255;

    @media (max-width: 767px){
      font-size: 18px;
    }

    ~ p{
      font-size: 18px;
      color: #515255;
      line-height: 23px;
    }
  }

  @media (max-width: 1200px){
    padding: 50px 0;
  }

  @media (max-width: 575px){
    h1{
      margin-bottom: 15px;
    }

    *{
      font-size: 18px;
    }
  }

  &_colored{
    position: relative;
    background: #123242;

    &:before{
      content: '';
      /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+53,0e4a62+100&0+0,0.3+53,1+100 */
      background: -moz-linear-gradient(left,  rgba(255,255,255,0) 0%, rgba(255,255,255,0.3) 53%, rgba(14,74,98,1) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(255,255,255,0.3) 53%,rgba(14,74,98,1) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(255,255,255,0.3) 53%,rgba(14,74,98,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#0e4a62',GradientType=1 ); /* IE6-9 */
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
    }
    
    color: #fff;
    
    *, p{
      font-size: 18px;
      color: #fff;
      font-size: 18px;
      line-height: 23px;
    }

    ul{
      list-style: none;

      li{
        &:before{
          content: '·';
          display: inline-block;
          line-height: 1px;
          font-size: 50px;
          height: 7px;
          overflow: hidden;
        }
        margin-bottom: 15px;
      }
    } 

    &-mobile{
      @media (max-width: 767px){
        background: linear-gradient(270.48deg, #0E4A62 0.46%, rgba(255, 255, 255, 0) 99.63%), #123242;
      }
    }
  }

  &__advantage{
    padding-right: 10px;
    margin-top: 35px;
  }
}

.h1-example{
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 0;
}

.h1-content{
  margin-bottom: 20px;
}

.max-width{
  max-width: 1142px;
  margin: auto;
  width: 100%;

  &-content{
    margin: auto;
    width: 100%;
    max-width: 1142px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.map-container{
  @media (max-width: 767px){
    padding: 0;
  } 
}


// -----------  FEEDBACK SECTION -----------------

#contacts{
  padding: .5rem .75rem 1.6rem;
  line-height: 1;
  resize: none;
  &::placeholder{
    line-height: 1;
  }
}

.form-control{
  padding: 0.8rem 0.75rem;
  font-size: 18px;

  @media (max-width: 767px){
    font-size: 14px;
  }

  &::placeholder{
    color: #C4C4C4;
  }

}

.feedback-form-note{
  p{
    font-size: 18px;
    color: #515255;

    @media (max-width: 767px){
      color: #fff;
    }
  }
}

#loader {
  display: none;
}

.feedback-form-button{
  max-width: 270px;
  width: 100%;
  border-radius: 25px;
  text-align: center;
  color: #C4C4C4;
  border: 1px solid #C4C4C4;
  background: transparent;
  font-size: 18px;
  padding: 7px;

  @media (max-width: 460px){
    max-width: 100%;
  }
}

#answer, #loader{
  @media (max-width: 767px){
    color: #fff;
  }
}

// --------- GALLERY -------------

.gallery{
  @media (max-width: 460px){
    padding: 0!important;
  }

  &__img-wrapper{
    // max-height: 481px;
    // overflow: hidden;
    display: flex;
    height: 100%;

    img{
      width: 100%;
    }

    @media (min-width: 1800px){
      -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%;
    }

    @media (min-width: 2400px){
      -webkit-box-flex: 0;
      -ms-flex: 0 0 481px;
      flex: 0 0 481px;
      max-width: 481px;
    }

    @media (max-width: 575px){
      -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }

    @media (max-width: 460px){
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}

.objects-slider{
  @media (max-width: 460px){
    //display: none!important;
  }

  .owl-item{
    @media (max-width: 575px){
      overflow: hidden;
    }
  }

  &__slide{
    @media (max-width: 460px){
      min-height: 290px;
      overflow: hidden;
    }

    img{
      width: 100%;

      @media (max-width: 460px){
        position: absolute;
        left: 50%;
        margin-left: -500px;
        height: 100%;
        width: auto!important;

        // ----------- module mobile slides ------------ //

        &.objects-slider__img-module-1{
          margin-left: -360px;
        }

        &.objects-slider__img-module-2{
          margin-left: -710px;
        }

        &.objects-slider__img-module-3{
          margin-left: -225px;
        }

        &.objects-slider__img-module-4{
          margin-left: -255px;
        }

        &.objects-slider__img-module-5{
          margin-left: -370px;
        }

        // ----------- tension mobile slides ------------ //

        &.objects-slider__img-tension-1{
          margin-left: -475px;
        }

        &.objects-slider__img-tension-2{
          margin-left: -425px;
        }

        &.objects-slider__img-tension-3{
          margin-left: -190px;
        }

        &.objects-slider__img-tension-4{
          margin-left: -470px;
        }

        &.objects-slider__img-tension-5{
          margin-left: -315px;
        }

        // ----------- construction based mobile slides ------------ //

        &.objects-slider__img-construction-based-1{
          margin-left: -600px;
        }

        &.objects-slider__img-construction-based-2{
          margin-left: -640px;
        }

        &.objects-slider__img-construction-based-3{
          margin-left: -655px;
        }

        &.objects-slider__img-construction-based-4{
          margin-left: -290px;
        }

        &.objects-slider__img-construction-based-5{
          margin-left: -690px;
        }
      }
    }
  }
}


// ------------- GALLEY SLIDER ---------------

.gallery-slider.off {
  display: flex;
}

.gallery-slider{

  @media (max-width: 460px){
    margin: 0!important;
  }

  &.owl-carousel{
    @media (min-width: 461px){
      width: auto;
    }
  }

  .owl-item{
    @media (max-width: 460px){
      margin-right: 0!important;
    }
  }

  .owl-nav{
    @media (max-width: 460px){
      position: absolute;
      display: flex;
      justify-content: space-between;
      width: 100%;
      top: calc(50% - 28px);

      > div{
        padding: 10px;
      }
      
      .disabled{
        visibility: hidden;
      }
    }
  }
}

#loader2{
  width: 100%;
  text-align: center;
}

footer{
  padding: 20px 15px;
  text-align: center;
  font-size: 18px;
  border-top: 1px solid #2D5E71;

  @media (max-width: 640px) {
    font-size: 14px;
  }

  *{
      color: #515255;
  }
}

.owl-dots {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 30px;
  height: 100%;
  justify-content: center;

  .owl-dot{
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #C4C4C4;
    margin: 10px;
    border: 4px solid #C4C4C4;
    font-size: 6px;
    text-align: center;

    &:hover, &.active{
      border-color: #1A8F3E;
      background: #fff;
    }
  }
}